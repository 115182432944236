export interface IGtmService {
  pushCookieConsentChanged(): void;
}

export class GtmService implements IGtmService {
  pushCookieConsentChanged() {
    this.push();
  }

  private push = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  };
}
